import React from 'react';

import {
  getAddressByOrderId,
  getOrderTotal,
  getStatus,
  isCollectionOrder,
} from 'redux/modules/orders/selectors/get-order';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { dayjs, FORMAT, isSameDay, isTomorrow } from 'utils/date';
import orderStatus from 'constants/orderStatus';
import { formatAsPounds } from 'utils/currency';
import addressAsString from 'utils/address-as-string';
import { useWtrSelector } from 'redux/hooks';
import {
  getDisplaySlotTime,
  getDriverNameByOrderId,
} from 'redux/modules/delivery-tracking/selectors';
import styles from './OrderDetails.scss';

export interface OrderDetailsProps {
  orderId: string;
}

const OrderDetails = ({ orderId }: OrderDetailsProps) => {
  const displaySlotTimes = useWtrSelector(state => getDisplaySlotTime(state, orderId));
  const driverName = useWtrSelector(state => getDriverNameByOrderId(state, orderId));
  const orderAddress = useWtrSelector(state => getAddressByOrderId(state, orderId));
  const orderTotal = useWtrSelector(state => getOrderTotal(state, orderId));
  const status = useWtrSelector(state => getStatus(state, orderId));
  const isCollection: boolean = useWtrSelector(state => isCollectionOrder(state, orderId));

  const formatSlotTimes = () => {
    if (!displaySlotTimes || !displaySlotTimes.startTime || !displaySlotTimes.endTime) {
      return null;
    }

    const slotStartTimeInUKTime = dayjs(displaySlotTimes.startTime).tz();
    const slotEndTimeInUKTime = dayjs(displaySlotTimes.endTime).tz();

    const fromTime = slotStartTimeInUKTime.format(FORMAT.HOUR_MINUTE_AMPM);
    const toTime = slotEndTimeInUKTime.format(FORMAT.HOUR_MINUTE_AMPM);

    if (isSameDay(slotStartTimeInUKTime, dayjs().tz())) {
      return `Today ${fromTime} - ${toTime}`;
    }

    if (isTomorrow(slotStartTimeInUKTime)) {
      return `Tomorrow ${fromTime} - ${toTime}`;
    }

    const formattedDate = slotStartTimeInUKTime.format(FORMAT.LONG_DAY_MONTH);
    return `${formattedDate} ${fromTime} - ${toTime}`;
  };

  const orderTotals = () => {
    if (!orderTotal || status === orderStatus.PAYMENT_FAILED) {
      return null;
    }

    return `${status === orderStatus.PAID ? 'Total' : 'Estimated total'}: ${formatAsPounds(
      orderTotal,
    )}`;
  };

  const slotTimes = formatSlotTimes();
  const formattedOrderTotal = orderTotals();
  const formattedAddress = orderAddress ? addressAsString(orderAddress) : null;

  return (
    <div className={styles.orderDetails}>
      {slotTimes && (
        <Typography
          styleAs="sectionHeading"
          noMargins
          className={styles.orderDetailsDateTime}
          data-testid="order-details-date-time"
        >
          {isCollection ? 'Collect: ' : null}
          {slotTimes}
        </Typography>
      )}
      {(formattedAddress || formattedOrderTotal) && (
        <Typography
          styleAs="paragraph"
          element="ul"
          noMargins
          className={styles.addressAndTotals}
          data-testid="order-details-address-and-totals"
        >
          {formattedAddress && (
            <li className={styles.address} data-testid="order-details-address">
              {isCollection ? 'From: ' : null}
              {formattedAddress}
            </li>
          )}
          {formattedOrderTotal && (
            <li className={styles.total} data-testid="order-details-order-totals">
              {formattedOrderTotal}
            </li>
          )}
        </Typography>
      )}
      {driverName && (
        <Typography
          styleAs="paragraph"
          element="p"
          noMargins
          className={styles.driverName}
          data-testid="order-details-driver-name"
        >
          Your delivery driver for today is {driverName}
        </Typography>
      )}
    </div>
  );
};

export default OrderDetails;
